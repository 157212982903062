import Vue from "vue";
import { GridPlugin } from "@syncfusion/ej2-vue-grids";
import TitleMixin from "@/mixins/title";
import SpinnerMixin from "@/mixins/spinner";
import { dateValidationRule } from "@/helpers/formValidation";
import { mapGetters } from "vuex";
Vue.use(GridPlugin);
export default Vue.extend({
    mixins: [TitleMixin, SpinnerMixin],
    data() {
        return {
            title: this.$t("dailyWorkAvailability.new"),
            id: null,
            nextId: 0,
            isEdit: false,
            offices: [],
            employee: {},
            isDayOff: [
                {
                    id: true,
                    name: this.$t("isDayOff.true")
                },
                {
                    id: false,
                    name: this.$t("isDayOff.false")
                }
            ],
            data: {
                officeId: null,
                validFrom: null,
                validTo: null,
                isDayOff: false,
                hours: []
            },
            validationOptions: {
                rules: {
                    officeId: {
                        required: true
                    },
                    validFrom: {
                        required: true,
                        date: [dateValidationRule]
                    },
                    validTo: {
                        required: true,
                        date: [dateValidationRule],
                        dateAfter: [
                            (args) => {
                                const validFrom = args.element.form.querySelector("#validFrom").ej2_instances[0].value;
                                const validTo = args.element.form.querySelector("#validTo").ej2_instances[0].value;
                                return validTo >= validFrom;
                            },
                            this.$t('dailyWorkAvailability.endDateMustBeGreaterThanStartDate')
                        ]
                    },
                    isDayOff: {
                        required: true
                    }
                }
            }
        };
    },
    async mounted() {
        this.showSpinner();
        try {
            this.isEdit = this.$route.params.id != undefined;
            this.offices = await this.$store.dispatch("office/getAll", {
                load: true
            });
            this.employee = await this.$store.dispatch(`employee/get`, {
                id: this.employeeId
            });
            if (this.isEdit) {
                const dailyWorkAvailability = await this.$store.dispatch(`dailyWorkAvailability/get`, {
                    employeeId: this.employeeId,
                    id: this.$route.params.id
                });
                if (dailyWorkAvailability.hours == null)
                    dailyWorkAvailability.hours = [];
                this.data = dailyWorkAvailability;
                for (const hour of this.data.hours) {
                    this.addValidation(hour.id);
                }
                //(document.getElementById('officeId') as any).ej2_instances[0].value = this.data.officeId;
                this.title = this.$t("dailyWorkAvailability.edit");
            }
            if (this.data.officeId == null && !this.hasMultipleOffices)
                this.data.officeId = this.getSingleOfficeId;
            if (this.data.hours.length === 0)
                this.addHours();
            this.setTitle(`${this.title} - ${this.employee.name} ${this.employee.surname}`);
            this.hideSpinner();
        }
        catch (errors) {
            this.hideSpinner();
            this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                error: errors[0].message
            }));
        }
    },
    computed: {
        ...mapGetters({
            hasMultipleOffices: "account/hasMultipleOffices",
            getSingleOfficeId: "account/getSingleOfficeId",
            getEmployeeId: "account/getEmployeeId"
        }),
        employeeId() {
            if (this.$route.params.employeeId != undefined)
                return this.$route.params.employeeId;
            return this.getEmployeeId;
        }
    },
    methods: {
        addHours() {
            this.nextId--;
            const newHour = {
                id: this.nextId,
                startTime: null,
                endTime: null
            };
            this.data.hours.push(newHour);
            this.addValidation(newHour.id);
        },
        removeHours(id) {
            const hour = this.data.hours.find(workHour => workHour.id == id);
            this.data.hours = this.data.hours.filter(hour => hour.id != id);
            if (hour != undefined) {
                this.removeValidation(id);
            }
        },
        addValidation(id) {
            const form = this.$refs["daily-work-availability-form"];
            form.addRules(`startTime_${id}`, {
                required: [
                    (args) => {
                        const isDayOff = document.getElementById("isDayOff").ej2_instances[0].value;
                        const inputVal = args.element.ej2_instances[0].value;
                        return isDayOff || inputVal != null;
                    },
                    this.$t('shared.requiredField')
                ]
            });
            form.addRules(`endTime_${id}`, {
                required: [
                    (args) => {
                        const isDayOff = document.getElementById("isDayOff").ej2_instances[0].value;
                        const inputVal = args.element.ej2_instances[0].value;
                        return isDayOff || inputVal != null;
                    },
                    this.$t('shared.requiredField')
                ]
            });
        },
        removeValidation(id) {
            const form = this.$refs["daily-work-availability-form"];
            form.removeRules(`startTime_${id}`);
            form.removeRules(`endTime_${id}`);
        },
        async create() {
            if (this.checkHoursFields()) {
                this.$toast.showDangerToast(this.$t("dailyWorkAvailability.hoursFieldsError"));
                return false;
            }
            const data = JSON.parse(JSON.stringify(this.data));
            if (data.isDayOff)
                data.hours = [];
            return await this.$store.dispatch(`dailyWorkAvailability/create`, {
                employeeId: this.employeeId,
                dailyWorkAvailabilityRequest: data
            });
        },
        createSuccess() {
            this.$toast.showSuccessToast(this.$t("dailyWorkAvailability.create.toastSuccessTitle"), this.$t("dailyWorkAvailability.create.toastSuccessContent"));
            this.backToGridPage();
        },
        update() {
            if (this.checkHoursFields()) {
                this.$toast.showDangerToast(this.$t("dailyWorkAvailability.hoursFieldsError"));
                return false;
            }
            // Copy data
            const data = JSON.parse(JSON.stringify(this.data));
            if (data.isDayOff)
                data.hours = null;
            return this.$store.dispatch(`dailyWorkAvailability/update`, {
                employeeId: this.employeeId,
                id: this.$route.params.id,
                dailyWorkAvailabilityRequest: data
            });
        },
        updateSuccess() {
            this.$toast.showSuccessToast(this.$t("dailyWorkAvailability.update.toastSuccessTitle"), this.$t("dailyWorkAvailability.update.toastSuccessContent"));
            this.backToGridPage();
        },
        cancel() {
            this.backToGridPage();
        },
        backToGridPage() {
            if (this.$route.params.employeeId != undefined)
                return this.$router.push(`/employees/${this.employeeId}/daily-work-availabilities`);
            return this.$router.push('/daily-work-availabilities');
        },
        checkHoursFields() {
            if (this.data.isDayOff)
                return false;
            return this.data.hours.some(hour => hour.startTime == null || hour.endTime == null || hour.startTime > hour.endTime);
        }
    }
});
